.App{
    background-color: #fff9f5;
}
.navbar {
    overflow: hidden;
    position: fixed;
    background-color: #fff1e5;
    z-index: 70;
    top: 0;
    width: 100%;
    max-width: 1440px;
  }
  .button{
    border: 2px solid #262a33;
    transition: background-color 0.5s, transform 0.5s;
  }
  .button:hover {
    color: #262a33;
    background-color: #fff;
    border: 2px solid #262a33; 
}
.intro{
    height: 625px;
    width: 100%;
    background-image: url("../images/George.jpeg");
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
}
.head-intro{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.intro-1{
    margin: 20px 50px 0px 50px;
}
.intro-2{
    margin: 0px 75px;
}
.first-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.first1{
    width: 50vw;
}
.first2{
    width: 50vw;
}
.second-section{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.second1{
    width: 50vw;
}
.second2{
    width: 50vw;
}
.third-section{
    background-color: #fff9f5;
}
.hr-line{
    border-top: 1px solid #262a33;
    margin: 0px 40px;
}
#tail a {
    text-decoration: underline;
    font-weight: bold;
    font-style: italic;
}
#tail a:hover {
    color: white;
}
.tail-section{
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.tail1{
    width: 50vw;
}
.tail2{
    width: 50vw;
}
#angelina-landscape img {
    width: 100vw;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .tail1{
        width: 70vw;
    }
    .tail2{
        width: 30vw;
    }
    .intro{
        max-height: 500px;
        background-size: contain;
    }
    #angelina-landscape {
        margin-right: 0;
        margin-left: 0;
    }
}
@media only screen and (max-width: 480px) {
    .navbar {
        overflow: hidden;
        position: fixed;
        background-color: #fff1e5;
        top: 0;
        width: 100%;
      }
    .head-intro{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .intro{
        max-height: 370px;
    }
    .intro-1{
        margin: 20px 50px 0px 50px;
    }
    .intro-2{
        margin: 0px 75px;
    }
    .first-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;;
    }
    .first1{
        width: 100vw;
        margin-top: -80px;
    }
    .first2{
        width: 100vw;
    }
    .second-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .second1{
        width: 100vw;
    }
    .second2{
        width: 100vw;
    }
    .hr-line{
        border-top: 1px solid #262a33;
        margin: 0px 15px;
    }
    .tail-section{
        display: flex;
        flex-direction: row;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .tail1{
        width: 100vw;
    }
    .tail2{
        width: 100vw;
    }
}